import './estilos/Contacto.css';

export default function Contacto (){

  return (
    <div className="img_contacto" >
      <h1 className="titulo_contacto">
        Contacto:
      </h1>
      <h1 className="contacto">
        lagrilla.online@gmail.com
      </h1>
      <h1 className="titulo_donaciones">
        Apóyanos:
      </h1>
      <a className="link_cafecito" href='https://cafecito.app/lagrillaonline' target="_blank" rel="noreferrer" ><img srcSet='https://cdn.cafecito.app/imgs/buttons/button_1.png 1x, https://cdn.cafecito.app/imgs/buttons/button_1_2x.png 2x, https://cdn.cafecito.app/imgs/buttons/button_1_3.75x.png 3.75x' src='https://cdn.cafecito.app/imgs/buttons/button_1.png' alt='Invitame un café en cafecito.app' /></a>
      </div>
  );
};
