
import React from "react";
import './estilos/Nosotros.css';

export default function Nosotros (){

  return (
    <div className="img_presentacion">
      <h1> 
        <span className="presentacion">
          Somos un equipo de noticias independiente.
        </span>
      </h1>
      <h1> 
        <span className="presentacion">
        Comunicamos hechos no opiniones.
        </span>
      </h1>
    </div>
  );
};
