import './App.css';
import Footer from './Footer';
import Header from './Header';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Noticias from './pages/Noticias';
import Noticia from './pages/Noticia';
import Nosotros from './pages/Nosotros';
import Contacto from './pages/Contacto';
import NotFound from './pages/NotFound';


export default function App() {
  return (
    <Router>
    <div className="App">
        <Header/>
        <Routes>
          <Route path="/" element={<Noticias/>}/>
          <Route path="/nosotros" element={<Nosotros/>}/>
          <Route path="/contacto" element={<Contacto/>}/>
          <Route path="/*" element={<NotFound/>}/>
          <Route path="/noticia/:noticiaid" element={<Noticia/>}/>
        </Routes>
        <Footer/>
    </div>
    </Router>
  );
}
