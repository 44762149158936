
import { useEffect, useState } from "react";
import popupStyles from "./custom-popup.module.css";
import PropTypes from "prop-types";
import MediaShareBar from "../components/mediaShareBar";


const CustomPopup = (props) => {
  const [showPop, setShowPop] = useState(false);
  let result;
  let newDate;


  const closeHandler = (e) => {
    setShowPop(false);
    props.onClose(false);
    document.documentElement.style.overflow = 'scroll';
    document.body.scroll = "yes";
  };

  useEffect(() => {
    setShowPop(props.show);        
    document.documentElement.style.overflow = 'scroll';
    document.body.scroll = "yes";
  }, [props.show]);


  if(props.show){
    document.documentElement.style.overflow = 'hidden';
    document.body.scroll = "no";
    result = props.fecha.split("-");
    newDate = result[2]+"/"+result[1]+"/"+result[0];
  }    

  return (
    <div style={{visibility: showPop ? "visible" : "hidden",opacity: showPop ? "1" : "0"}} className={popupStyles.overlay}>
      {props.show ? (
      <div className={popupStyles.popup}>
        <span className={popupStyles.close} onClick={closeHandler}>&times;</span>
        <p className={popupStyles.popup_titulo}>{props.titulo}</p>
        <p className={popupStyles.popup_fecha}>{newDate}</p>
        <p className={popupStyles.popup_cuerpo}>{props.descripcion}</p>
        <p className={popupStyles.popup_cuerpo}>{props.cuerpo}</p>
        
        <p className={popupStyles.popup_tituloBold}>{"Temas: "}</p>
        <div style={{display:'flex',flexdirection:'horizontal'}}>
          {props.tags.map( (txt,index) => {
            return(
                    <div key={index}>
                      <label className={popupStyles.popup_adicionales} key={index}>{txt}</label>
                      <span>,&nbsp;</span>
                    </div>
                  )}
          )}
        </div>
        
        {props.links!="" && <p className={popupStyles.popup_tituloBold} style={{marginTop:'1%'}}>{"Fuente: "}</p>}
        <div style={{display:'flex',flexdirection:'horizontal'}}>
          {props.links.map( (txt,index) => {
          var id=index+1;
          return(
                <div key={index}>
                  <a className={popupStyles.popup_adicionales} target="_blank" rel="noreferrer" href={"https://"+txt}>{"Link"+id}</a>
                  <span>&nbsp;&nbsp;</span>
                </div>
                )}
          )}
        </div>

        <div className={popupStyles.content}>{props.children}</div>
        <MediaShareBar
          className={"mediaShare"}
          path={props.noticia} //localhost:3000/api/getOne/629ce140e3a451f5533a8416
          titulo={"rta.titulo"}>
        </MediaShareBar>
      </div>
      ):(<p>Cargando...</p>)}
    </div>
  );
};

CustomPopup.propTypes = {
  titulo: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
export default CustomPopup;
