import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon } from 'react-share';
import icon from "../Icon/share_100.png";
import './mediaShareBar.css';
import PropTypes from "prop-types";
import { useEffect } from "react";

const MediaShareBar = (props) => {

const URL_Grilla = "https://lagrilla.online";

useEffect(()=>{
    window.dataLayer = window.dataLayer || [];
  });

function copyURL(){
    navigator.clipboard.writeText(URL_Grilla+"/noticia/"+props.path);
    window.dataLayer.push({'event': "copyLink"});    
    }

function twitterClick(){
    window.dataLayer.push({'event': "twitter"});    
    }

function facebookClick(){
    window.dataLayer.push({'event': "facebook"}); 
    }

function linkedinClick(){
    window.dataLayer.push({'event': "linkedin"}); 
    }

return(
        <div className={props.className}>
            <button className="nota_compartir" onClick={copyURL}>
                    <img src={icon} alt='description' width="100%" backgroundcolor="grey"/>
            </button>

            <div>
                <TwitterShareButton
                url={URL_Grilla+"/noticia/"+props.path}
                quote={props.titulo}
                hashtag="#LGO"
                onClick={twitterClick}
                >
                <TwitterIcon size={33} />
                </TwitterShareButton>
            </div>

            <div>  
                <FacebookShareButton
                url={URL_Grilla+"/noticia/"+props.path} //"http://localhost:3000"+path
                quote={props.titulo}
                hashtag="#LGO"
                onClick={facebookClick}
                >
                <FacebookIcon size={33} />
                </FacebookShareButton>  
            </div>

            <div>
                <LinkedinShareButton
                url={URL_Grilla+"/noticia/"+props.path}
                quote={props.titulo}
                hashtag="#LGO"
                onClick={linkedinClick}
                >
                <LinkedinIcon size={33} />
                </LinkedinShareButton>
            </div>
    </div>
)

};

MediaShareBar.propTypes = {
    titulo: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired
  };

export default MediaShareBar;