import React, { useRef, useEffect, useState } from "react";
import ListingPageComponent from "./ListingPageComponent";

function ListingPageContainer(props) {
  const listInnerRef = useRef();
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [newsList, setNewsList] = useState([]);
  const [lastList, setLastList] = useState(false);

  useEffect(() => {    

    var myHeaders = new Headers();
    myHeaders.append("x-api-key", process.env.REACT_APP_MY_API_KEY);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    let rta="";
    const fetchData = async () => {    
      if(props.filtro==="recientes")
      {
        await fetch(`https://lgo-api.vercel.app/api/getPage?page=${currPage}&limit=5`,requestOptions)//URL API
        .then((response) => response.json())
        .then((data) => 
          {
            rta=data
            //console.log(rta);
          }
        )    
      }
      else
      {
        let filtro=props.filtro.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        filtro=filtro.toLowerCase();        
        await fetch(`https://lgo-api.vercel.app/api/getTopic?topic=${filtro}&page=${currPage}&limit=5`,requestOptions)//URL API
        .then((response) => response.json())
        .then((data) => 
          {
            rta=data
            //console.log(filtro);
          }
        )    
      }

        if (!rta.length) {
          setLastList(true);//hay data nueva
          return;
        }
        setPrevPage(currPage);//actualizo la nueva pagina
        setNewsList([...newsList, ...rta]);//cargo la data en la lista
        //console.log(newsList);
      
    };

    
  //Chanchullo para disparar el fetch en la version mobile 
    const checkScroll = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 100){
        setCurrPage(currPage + 1);
      }
    }    
    window.addEventListener('scroll', checkScroll);
  //Chanchullo para disparar el fetch en la version mobile 


    if (!lastList && prevPage !== currPage) {//si no hya data nueva y la pagina previa es distinta a la actual
      fetchData();
    }
    
  }, [currPage, lastList, prevPage, newsList,props.filtro]);

  const handleScroll = () => {    
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      //console.log("scrollTop: "+scrollTop);
      //console.log("scrollHeight: "+scrollHeight);
      //console.log("clientHeight: "+clientHeight);
      if ((scrollTop + clientHeight + 2) > scrollHeight) {
        setCurrPage(currPage + 1);
        //console.log("currPage: "+currPage);
      }
    }
  };    

  return (
    <ListingPageComponent
      scrollEvent={handleScroll}
      listInnerRef={listInnerRef}
      newsList={newsList}
    />
  );
}

export default ListingPageContainer;
