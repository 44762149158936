import mainLogo from'./logo.png';

export default function Header(){

    return(      
      <header>
      <nav className='navbar navbar-expand-lg navbar-dark mynav'>
      <a href="/">
        <img src={mainLogo} className='nav-logo' alt='description' />
      </a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon hamburger"></span>
      </button>
  
      <div className="collapse navbar-collapse mynavcollapse" id="navbarSupportedContent">
        <ul className="navbar-nav">  
        <li className="mynavitems">
            <a className="nav-link" href="/">Noticias</a>
          </li>
          <li className="mynavitems">
            <a className="nav-link" href="/contacto">Contacto</a>
          </li>
          <li className="mynavitems">
            <a className="nav-link" href="/nosotros">Nosotros</a>
          </li>
        </ul>
      </div>
    </nav>
    </header>
    )
  }