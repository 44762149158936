import { React } from "react";
import Card from '../Card';
import moment from 'moment';
import ScrollButton from '../components/ScrollButton';
import background from "../img/fondo.jpeg";

function ListingPageComponent({ scrollEvent, listInnerRef, newsList }) {
  return (
      <div style={{ height: "auto", minHeight:"100vh", backgroundImage:`url(${background})`,backgroundRepeat:"no-repeat",backgroundSize:"cover",backgroundPosition:"center",display:"flex",flexDirection:"column",alignItems:"center"}}
        onScroll={scrollEvent}
        ref={listInnerRef}
      >
        {newsList.map((post) => {
          let parsed = moment(post.fecha).format('YYYY-MM-DD');
          post.fecha= parsed; 
          return (            
            <Card key={post._id}
            imagen={post.imagen}
            titulo={post.titulo} 
            descripcion={post.descripcion} 
            fecha={post.fecha} 
            cuerpo={post.cuerpo} 
            links={post.links} 
            tags={post.tags} 
            noticia={post._id}
            >
          </Card>);
        })}
        <ScrollButton />
      </div>
  );
}

export default ListingPageComponent;
