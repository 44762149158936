import React from "react";
import ListingPageContainer from "../ListingPages/ListingPageContainer";
import {useState} from "react";
import {useLocation} from 'react-router-dom';
import SearchBar from '../components/SearchBar';


export default function Noticias (){
  const location = useLocation();
  const path = location.pathname;
  const [filter, setFilter] = useState("recientes");
  const [display, setDisplay] = useState(
    path === "/" ? true : false
  );

  const selectFilter = num => {
    setFilter(num);
    //console.log(filter);
  };

//Chanchullo uso la key={filter} para forzar el re render
    return (
      <section>
          {display && <SearchBar selectFilter={selectFilter}/>}
          <ListingPageContainer key={filter} className="infoSection" filtro={filter}/>
      </section>
    );  
                   

};
