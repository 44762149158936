import React, { useState, useRef, useId } from "react";
import CustomPopup from "./components/CustomPopup";

export default function Card(props)
{
    let [show, setShow] = useState(false);
    const id = useId();
    const card = useRef(id);

    const popupCloseHandler = () => {
        setShow(false);
      };

    let result = props.fecha.split("-");
    let newDate = result[2]+"/"+result[1]+"/"+result[0];
    let urlImagen ="/images/" + props.imagen + ".png";

    return(       
            <div className="popupholder">
                <div className="flexContainer" onClick={handleClick} ref={card} >
                    
                    <div className="imagen" style={{backgroundImage: `url(${urlImagen})` }}> 
    

                    </div> 
                    
                    <div className="noticia">
                        <b className="noticia_titulo">{props.titulo}</b>
                        <p className="noticia_descripcion">{props.descripcion}</p>
                        <p className="noticia_fecha">{newDate}</p>
                    </div>
                </div>

                <CustomPopup
                onClose={popupCloseHandler}
                show={show}
                titulo={props.titulo}
                fecha={props.fecha}
                descripcion={props.descripcion}
                cuerpo={props.cuerpo}                
                links={props.links}    
                tags={props.tags}        
                noticia={props.noticia}
                >
                </CustomPopup>        
         
            </div>
     
    )

    function handleClick(){
        setShow(true);    
    }

}

