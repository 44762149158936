import './SearchBar.css';
import React, { useEffect } from "react";


export default function SearchBar ({selectFilter}) {

// spread operator will display all the values from our category section of our data while Set will only allow the single value of each kind to be displayed
  const menuItems = [...new Set(["Política", "Economía","Cripto"])];

  useEffect(()=>{
    window.dataLayer = window.dataLayer || [];
  });


  return (
      <div className="filterBar">
        {menuItems.map((Val, id) => {
          return (
            <button
              className="filterBtn"
              key={id}
              onClick={event => {
                                  selectFilter(Val);      
                                  window.dataLayer.push({'event': Val});                                         
                                }     
                      }
            >
              {Val}
            </button>
          );  
        })}
        <button
          className="filterBtn"
          onClick={event => {
                              selectFilter("recientes");   
                              window.dataLayer.push({'event': "recientes"});    
                            }
          }
        >
          Recientes
        </button>
       </div>
  );
}