import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import './estilos/Noticia.css';
import moment from 'moment';
import MediaShareBar from "../components/mediaShareBar";


export default function Noticia (){
  const [isLoading, setIsLoading] = useState(true);
  const [rta, setrta] = useState("");
  const {noticiaid} = useParams();
  var path;
  var newDate;

  useEffect(() => {    

    var myHeaders = new Headers();
    myHeaders.append("x-api-key", process.env.REACT_APP_MY_API_KEY);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch(`https://lgo-api.vercel.app/api/getOne/${noticiaid}`,requestOptions)//URL API
      .then((response) => response.json())
      .then((data) => 
        {          
          setrta(data);
          setIsLoading(false);
        }
      )    
    }, [noticiaid]); 

    
    if (isLoading) {
      return (
        <div className="noticia">
          <p style={{fontSize:"1rem"}} >Cargando...</p>
        </div>
      );
    } 
    else
    {
        let parsed = moment(rta.fecha).format('YYYY-MM-DD');
        let result = parsed.split("-");
        newDate = result[2]+"/"+result[1]+"/"+result[0];
        path=noticiaid.toString();  
    }
 

    return (
      <section>
          <div className="area">

            <div className="columnaImagen">
              <img src={"/images/" + rta.imagen + ".png"} className="imagenNoticia" alt='Imagen' width="100%" height="auto" loading="lazy"/>
              <MediaShareBar
                className={"mediaShare"}
                path={path}
                titulo={rta.titulo}>
              </MediaShareBar>
            </div>

            <div className="columnaInfo">
              <b className="nota_titulo">{rta.titulo}</b>
              <p className="nota_fecha">{newDate}</p>
              <p className="nota_cuerpo">{rta.descripcion}</p>
              <p className="nota_cuerpo">{rta.cuerpo}</p>

              <p className="nota_tituloBold">{"Temas: "}</p>
              <div style={{display:'flex',flexdirection:'horizontal',marginBottom:'0.5rem'}}>
                {rta.tags.map( (txt,index) => {
                return(
                        <div key={index}>
                          <label key={index}>{txt}</label>
                          <span>,&nbsp;</span>
                        </div>
                      )}  
                )}
              </div>

              
             {rta.links!="" && <p className="nota_tituloBold">{"Fuente: "}</p>}
              <div style={{display:'flex',flexdirection:'horizontal'}}>
                {
                  rta.links.map( (txt,index) => {
                  var id=index+1;
                  return(
                        <div key={index}>
                          <a target="_blank" rel="noreferrer" href={"https://"+txt}>{"Link"+id}</a>
                          <span>&nbsp;&nbsp;</span>
                        </div>
                      )}
                )}
              </div>
            </div>  
          </div>

          
      </section>
    );  
                   
};
