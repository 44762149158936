

export default function NotFound (){

  return (
    <div>
      <h1 style = {{display: "flex",justifyContent:"center",color:"black"}}>
        Error 404: Resource not found
      </h1>
    </div>
  );
};